<template>
  <div>
    <v-card flat class="mt-4">
      <v-row no-gutters>
        <v-col
          v-if="$vuetify.breakpoint.name != 'xs'"
          class="mt-4"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              outlined
              :items="ShiftItems"
              :disabled="isLoading == true"
              item-text="workshift_name"
              item-value="workshift_id"
              style="max-width: 220px"
              @change="get_swipedata_pagination()"
              class=""
            ></v-select>
            <v-select
              label="Filter by Year"
              v-model="selectedYear"
              :disabled="isLoading == true"
              @change="get_swipe_data_yearfilter()"
              :items="filteredYears"
              class="ml-2"
              style="max-width: 120px"
              dense
              outlined
            >
            </v-select>
            <v-select
              label="Filter by Month"
              :disabled="isLoading == true"
              item-text="text"
              item-value="value"
              v-model="selectedMonth"
              @change="get_swipedata_pagination()"
              v-on:change="get_all_days_of_month()"
              :items="formattedMonthItems"
              dense
              outlined
              class="ml-2"
              style="max-width: 150px"
            >
            </v-select>
            <v-select
              label="Status"
              :disabled="isLoading == true"
              v-model="statusfilter"
              :items="statusfilteritems"
              item-text="text"
              item-value="value"
              outlined
              dense
              class="ml-2"
              style="max-width: 150px"
              @change="get_swipedata_pagination()"
            />
            <v-autocomplete
              label="Filter by Location"
              dense
              outlined
              item-text="location_name"
              item-value="location_id"
              style="max-width: 200px"
              :items="locationItems"
              @change="get_swipedata_pagination()"
              v-model="LocationData"
              class="ml-2 mr-2"
            ></v-autocomplete>
            <v-text-field
              v-model="search"
              label="Search"
              placeholder="Min 3 Character"
              dense
              append-icon="mdi-magnify"
              class="mt-0 ml-2"
              style="max-width: 220px"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="legendsDialog = true">
                  <v-btn
                    small
                    v-on="on"
                    class="mt-n4 mb-2 gradient-bg white--text"
                  >
                    <v-icon class="white--text">mdi-format-color-fill</v-icon>
                  </v-btn>
                </a>
              </template>
              <span class="white--text">Legends</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              outlined
              :items="ShiftItems"
              item-text="workshift_name"
              item-value="workshift_id"
              @change="get_swipedata_pagination()"
              class="mr-2"
            ></v-select>
            <v-select
              label="Filter by Year"
              v-model="selectedYear"
              :disabled="isLoading == true"
              @change="get_swipe_data_yearfilter()"
              :items="filteredYears"
              class="mr-2"
              dense
              outlined
            >
            </v-select>
            <v-select
              label="Filter by Month"
              :disabled="isLoading == true"
              item-text="text"
              item-value="value"
              v-model="selectedMonth"
              @change="get_swipedata_pagination()"
              v-on:change="get_all_days_of_month()"
              :items="formattedMonthItems"
              dense
              outlined
            >
            </v-select>
            <v-select
              label="Status"
              :disabled="isLoading == true"
              v-model="statusfilter"
              :items="statusfilteritems"
              item-text="text"
              item-value="value"
              @change="get_swipedata_pagination()"
            />
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-text-field
              v-model="search"
              label="Search"
              dense
              append-icon="mdi-magnify"
              class="mt-0"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="legendsDialog = true">
                  <v-btn
                    small
                    v-on="on"
                    class="mr-2 mt-n4 mb-2 gradient-bg white--text"
                  >
                    <v-icon class="white--text">mdi-format-color-fill</v-icon>
                  </v-btn>
                </a>
              </template>
              <span class="white--text">Legends</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="SwipeDataHeaders"
          :items="SwipeDataItems"
          dense
          :height="height"
          class="overflow-y-auto overflow"
          :search="search"
          :fixed-header="fixedHeader"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          :options.sync="pagination"
          @update:options="get_swipe_data_report()"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
          :sort-by="['user_name']"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text alertcss"
              >No Data available for the selected Year & Month.
            </v-alert>
          </template>
          <!--item.day${col+1} :-> (array values 0-30 + 1) -->
          <template
            v-for="col in days_in_current_month"
            v-slot:[`item.day${col}`]="{ item }"
          >
            <SwipeDataChip :key="col" :item="item.monthly[col-1]" />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="overlaycheck == 1">
      <Overlay :overlay="overlay" />
    </div>
    <Legends
      :legendsDialog="legendsDialog"
      @close_legends="legendsDialog = false"
    />
  </div>
</template>

<script>
import SwipeDataChip from "./SwipeDataChip.vue";
import { API, graphqlOperation } from "aws-amplify";
import { monthly_report_detailsv2 } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import Legends from "../../Legends.vue";
import { Getshiftwork } from "@/mixins/GetWorkshift.js";
import { GetLocation } from "@/mixins/GetLocationList.js";

export default {
  components: {
    SwipeDataChip,
    Overlay,
    Legends,
  },
  mixins: [Getshiftwork, GetLocation],
  data: () => ({
    fixedHeader: true,
    legendsDialog: false,
    LocationData: "ALL",
    locationItems: [],
    search: "",
    next_token: null,
    isLoading: false,
    overlay: false,
    overlaycheck: 0,
    SwipeDataHeaders: [],
    SwipeDataItems: [],
    statusfilter: "ACTIVE",
    statusfilteritems: [
      { text: "ACTIVE", value: "ACTIVE" },
      { text: "INACTIVE", value: "DEACTIVE" },
    ],
    SwipeDataItemsData: [],
    pagination: {
      rowsPerPage: 32,
      page: 1,
    },

    days_in_current_month: [],

    ShiftItems: [],
    shiftName: "ALL",
    formattedMonthItems: [],
    selectedYear: new Date().getFullYear(),
    selectedMonth: "",
    selectedDate: "",
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  async created() {
    this.height = window.innerHeight - 220;
    await this.Getshiftwork();
    await this.GetLocation();
    await this.fetchdetails();

    await this.get_swipe_data_report();
  },
  computed: {
    filteredYears() {
      const currentYear = new Date().getFullYear();
      var dateComponents = this.selectedDate.split(/[\/,\s:]+/);
      // Extracting components
      var month = parseInt(dateComponents[0], 10) - 1; // Month is zero-indexed in JavaScript Date
      var day = parseInt(dateComponents[1], 10);
      var year = parseInt(dateComponents[2], 10);
      var hour = parseInt(dateComponents[3], 10);
      var minute = parseInt(dateComponents[4], 10);
      var second = parseInt(dateComponents[5], 10);
      var period = dateComponents[6]; // "AM" or "PM"

      // Adjust hour based on AM/PM
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      var date = new Date(year, month, day, hour, minute, second);

      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },
  async mounted() {
    this.selectedDate = this.get_date(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    //  this.selectedDate='03/02/2024, 03:35:44 PM';

    await this.month_get();
    await this.get_all_days_of_month();
  },
  watch: {
    search() {
      if (this.search != "") {
        if (this.search.length >= 3) {
          this.get_swipe_data_report();
        }
      } else {
        this.get_swipe_data_report();
      }
    },
  },
  methods: {
    async get_swipe_data_yearfilter() {
      await this.month_get();
      this.next_token = null;
      this.SwipeDataItems = [];

      await this.get_all_days_of_month();
      await this.get_swipe_data_report();
    },
    month_get() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonthIndex = currentDate.getMonth();

      let newMonthItems = [];

      const month = this.selectedDate.match(/^(\d{2})\//)[1];
      // if (this.selectedYear === new Date(this.selectedDate).getFullYear()) {

      //   newMonthItems = this.monthNames.slice();
      // }

      if (this.selectedYear < currentYear) {
        newMonthItems = this.monthNames.slice();
      } else if (this.selectedYear === currentYear) {
        newMonthItems = this.monthNames.slice(0, currentMonthIndex + 1);
      } else {
        newMonthItems = this.monthNames.slice();
      }

      this.monthItems = newMonthItems.slice();

      this.formattedMonthItems = this.monthItems.map((month, index) => ({
        text: month,
        value: (index + 1).toString().padStart(2, "0"),
      }));
      this.selectedMonth = (currentMonthIndex + 1).toString().padStart(2, "0");
      if (this.selectedYear == new Date(this.selectedDate).getFullYear()) {
        const startIndex = this.formattedMonthItems.findIndex(
          (item) => item.value === month
        );

        const filteredArray =
          startIndex !== -1 ? this.formattedMonthItems.slice(startIndex) : [];

        this.formattedMonthItems = [];
        this.formattedMonthItems = filteredArray;
        this.selectedMonth = month;
      }

      this.$forceUpdate();
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_swipedata_pagination() {
      this.next_token = null;
      this.SwipeDataItems = [];
      this.get_swipe_data_report();
    },

    fetchdetails() {
      this.GetWorkshiftitems.forEach((element) => {
        this.ShiftItems.push({
          workshift_name: element.workshift_name,
          workshift_id: element.workshift_id,
        });
      });
      this.ShiftItems.unshift("ALL");
      this.GetLocationitems.forEach((element) => {
        this.locationItems.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
      });
      this.locationItems.unshift({
        location_name: "Any Locations",
        location_id: "ANY_LOCATIONS",
      });
      this.locationItems.unshift("ALL");
    },

    get_all_days_of_month() {
      this.SwipeDataHeaders = [];
      this.SwipeDataHeaders = [
        {
          text: "Member Id",
          value: "employee_id",
          sortable: false,
          width: "auto",
        },
        {
          text: "Member Name",
          value: "user_name",
          sortable: false,
          width: "auto",
        },
        {
          text: "Location Name",
          value: "location_name",
          sortable: false,
          width: "auto",
        },

        {
          text: "Σ Duration",
          value: "total_minutes",
          sortable: false,
          width: "auto",
        },
        {
          text: "P",
          value: "no_of_present_days",
          sortable: false,
          width: "auto",
        },
        {
          text: "L",
          value: "no_of_leave_days",
          sortable: false,
          width: "auto",
        },
        {
          text: "A",
          value: "no_of_absent_days",
          sortable: false,
          width: "auto",
        },
      ];
      const daysInMonth = new Date(
        this.selectedYear,
        this.selectedMonth,
        0
      ).getDate();
      this.days_in_current_month = daysInMonth;

      for (let i = 1; i <= daysInMonth; i++) {
        this.SwipeDataHeaders.push({
          text: i,
          value: `day${i}`,
          sortable: false,
          width: "auto",
        });
      }
    },
    async get_swipe_data_report() {
      this.overlaycheck = 1;
      this.overlay = true;
      this.isLoading = true;
      if (this.selectedMonth != "") {
        try {
          await API.graphql(
            graphqlOperation(monthly_report_detailsv2, {
              input: {
                organisation_id:
                  this.$store.getters.get_org_details[0].organisation_id,
                year: this.selectedYear,
                month: this.selectedMonth,
                workshift_id: this.shiftName,
                nextToken: this.next_token,
                limit: 32,
                // this.LocationData != "ALL" ? 250 : 32,
                user_name: this.search,
                location_id: this.LocationData,
                user_status: this.statusfilter,
              },
            })
          ).then((res) => {
            this.SwipeDataItemsData =
              JSON.parse(res.data.monthly_report_detailsv2) != null
                ? JSON.parse(res.data.monthly_report_detailsv2)
                : [];
            var response = this.SwipeDataItemsData.data;

            let array = this.SwipeDataItems.concat(response);
            this.SwipeDataItems = array;
            const uniqueArrayOfObjects = this.SwipeDataItems.filter(
              (obj, index, self) =>
                index === self.findIndex((o) => o.user_id === obj.user_id)
            );
            this.SwipeDataItems = uniqueArrayOfObjects;

            // console.log(this.SwipeDataItems);
            this.next_token = this.SwipeDataItemsData.nextToken;
            console.log(this.next_token, "nexttoken");
            for (let i = 0; i < this.SwipeDataItems.length; i++) {
              for (let key in this.SwipeDataItems[i]) {
                this.SwipeDataItems[i].total_hrs = 0;
                this.SwipeDataItems[i].total_mins = 0;
                if (typeof this.SwipeDataItems[i][key] == "object") {
                  for (let j = 0; j < this.SwipeDataItems[i][key].length; j++) {
                    if (
                      this.SwipeDataItems[i][key][j].attendence != undefined ||
                      this.SwipeDataItems[i][key][j] != undefined
                    ) {
                      if (this.SwipeDataItems[i][key][j].attendence == "P") {
                        if (
                          this.SwipeDataItems[i][key][j].first_swipe !=
                          this.SwipeDataItems[i][key][j].last_swipe
                        ) {
                          this.SwipeDataItems[i].total_hrs += Number(
                            this.SwipeDataItems[i][key][j].total_duration.split(
                              ":"
                            )[0]
                          );
                          this.SwipeDataItems[i].total_mins += Number(
                            this.SwipeDataItems[i][key][j].total_duration.split(
                              ":"
                            )[1]
                          );
                        }
                      }
                    }
                  }
                }
              }
              this.SwipeDataItems[i].minutes_to_hrs = this.timeConvert(
                this.SwipeDataItems[i].total_mins
              );
              this.SwipeDataItems[i].total_hrs_mins =
                this.SwipeDataItems[i].total_hrs +
                Number(this.SwipeDataItems[i].minutes_to_hrs.split(":")[0]) +
                "h" +
                " : " +
                +Number(this.SwipeDataItems[i].minutes_to_hrs.split(":")[1]) +
                "m";
            }
            this.overlaycheck = 0;
            this.overlay = false;
            this.isLoading = false;
            this.$forceUpdate();
            // console.log(this.SwipeDataItems);
          });
        } catch (err) {
          console.log(err);
          this.overlaycheck = 0;
          this.$store.commit("snackbar/showMessage", {
            content: err.errors[0].message,
            color: "red",
          });
          this.overlay = false;
          this.isLoading = false;
        }
      }
    },
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + ":" + rminutes;
    },
  },
};
</script>

<style></style>
