<template>
  <v-dialog persistent v-model="legendsDialog" width="500px">
    <v-card flat class="overflow-hidden">
      <v-toolbar dense dark class="gradient-bg2 elevation-0"
        >Legends for Monthly Reports
        <v-spacer></v-spacer>
        <v-btn class="" text dark @click="close_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="pt-4">
          <v-row
            class="my-2"
            v-for="(item, idx) in legends_array"
            :key="idx"
            no-gutters
          >
            <v-col
              class="font-weight-bold"
              cols="12"
              sm="12"
              md="4"
              xs="12"
              lg="4"
              xl="4"
              align="left"
              justify="left"
            >
              {{ item.label }}
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              xs="12"
              lg="4"
              xl="4"
              align="left"
              justify="left"
            >
              {{ item.status }}
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              xs="12"
              lg="4"
              xl="4"
              align="center"
              justify="center"
            >
              <v-chip small :color="item.color"> </v-chip>
            </v-col>
          </v-row>
        </div>
      </v-card-text> </v-card
  ></v-dialog>
</template>
<script>
export default {
  props: {
    legendsDialog: Boolean,
  },
  data: () => ({
    dialog: false,
    legends_array: [
      {
        label: "Early Start",
        status: "Duration Satisfied",
        color: "#0277BD",
      },
      {
        label: "Late Start",
        status: "Duration Low",
        color: "#FB8C00",
      },
      {
        label: "Early Start",
        status: "Duration Low",
        color: "#FDD835",
      },
      {
        label: "Late Start",
        status: "Duration Satisfied",
        color: "#43A047",
      },
      {
        label: "Single Late Start",
        status: "",
        color: "#e3a07b",
      },
      {
        label: "Single Early Start",
        status: "",
        color: "#00ACC1",
      },
      {
        label: "Weekly Off (WO)",
        status: "",
        color: "#b0b0b0",
      },
      {
        label: "Absent (A)",
        status: "",
        color: "red darken-2",
      },
      {
        label: "Half Leave (HL)",
        status: "",
        color: "#004D40",
      },
      {
        label: "Full Leave (FL)",
        status: "",
        color: "#006064",
      },
      {
        label: "Deactivated (DA)",
        status: "",
        color: "brown",
      },
      {
        label: "Holiday",
        status: "",
        color: "purple",
      },
      {
        label: "No Swipe (N/S)",
        status: "",
        color: "grey darken-2",
      },
    ],
  }),
  methods: {
    close_dialog() {
      this.$emit("close_legends");
    },
  },
};
</script>

<style></style>
