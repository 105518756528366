<template>
  <div>
    <!-- <span>{{ item.no_of_swipes != undefined ? item.no_of_swipes : "" }}</span> -->

    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on }">
        <v-chip
          x-small
          v-if="item"
          class="white--text"
          :color="get_color(item)"
          v-on="on"
          label
          >{{
            item.attendence == "P" ? item.total_duration : item.attendence
          }}</v-chip
        >
      </template>
      <span v-if="item != undefined" class="white--text">
        First Swipe :
        {{
          item.attendence == "P"
            ? new Date(item.first_swipe * 1000).toLocaleTimeString()
            : "00:00"
        }}
        <br />
        Last Swipe :
        {{
          item.attendence == "P"
            ? new Date(item.last_swipe * 1000).toLocaleTimeString()
            : "00:00"
        }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    get_color(item) {
      if (item.attendence != "P") {
        switch (item.attendence) {
          case "N/A":
            return "pink";
          case "A":
            return "red darken-2";
          case "H":
            return "purple";
          case "W/O":
            return "#b0b0b0";
          case "L":
            return "#81f0abb";
          case "D/A":
            return "brown";
          case "N/S":
            return "grey darken-2";
          case "HL":
            return "#004D40";
          case "FL":
            return "#006064";
        }
      }
      if (item.attendence == "P") {
        if (
          item.organisation_start_epoch != undefined &&
          item.organisation_end_epoch != undefined &&
          item.employe_total_duartion_epoch != undefined &&
          item.organisation_total_duration_epoch != undefined
        ) {
          if (item.last_swipe != item.first_swipe) {
            if (
              item.first_swipe <= item.organisation_start_epoch &&
              item.employe_total_duartion_epoch >=
                item.organisation_total_duration_epoch
            ) {
              return "#0277BD";
            } else if (
              item.first_swipe >= item.organisation_start_epoch &&
              item.employe_total_duartion_epoch <
                item.organisation_total_duration_epoch
            ) {
              return "#FB8C00";
            } else if (
              item.first_swipe < item.organisation_start_epoch &&
              item.employe_total_duartion_epoch <
                item.organisation_total_duration_epoch
            ) {
              return "#FDD835";
            } else if (
              item.first_swipe > item.organisation_start_epoch &&
              item.employe_total_duartion_epoch >=
                item.organisation_total_duration_epoch
            ) {
              return "#43A047";
            }
          } else if (item.last_swipe == item.first_swipe) {
            if (item.first_swipe > item.organisation_start_epoch) {
              return "#e3a07b";
            } else if (item.first_swipe < item.organisation_start_epoch) {
              return "#00ACC1";
            }
          } else {
            return "black";
          }
        }
      }
    },
  },
};
</script>

<style></style>
